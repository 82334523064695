<template>
  <div class="container">
    <a class="btn__back" @click="$router.go(-1)">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 20C15.5141 20 20 15.5141 20 10C20 4.48586 15.5141 0 10 0C4.48586 0 0 4.48586 0 10C0 15.5141 4.48586 20 10 20ZM6.21121 9.68795L11.4263 4.47283C11.5115 4.38763 11.6228 4.34591 11.734 4.34591C11.8453 4.34591 11.9565 4.38849 12.0417 4.47283C12.2112 4.64232 12.2112 4.91786 12.0417 5.08735L7.13429 9.99479L12.0409 14.9031C12.2104 15.0726 12.2104 15.3481 12.0409 15.5176C11.8714 15.6871 11.5958 15.6871 11.4263 15.5176L6.21121 10.3025C6.04172 10.133 6.04172 9.85744 6.21121 9.68795Z"
          fill="#DB353A"
        />
      </svg>
      <span>Back</span>
    </a>

    <h2 class="leading">Enter your Account Number</h2>

    <form class="form" @submit.prevent="submitForm" novalidate="true">
      <div class="form__group">
        <label for="accountNumber" class="form__label">
          <input
            type="number"
            id="accountNumber"
            name="accountNumber"
            placeholder="Account Number"
            class="form__input"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            v-model.lazy="$v.form.accountNumber.$model"
            :class="{ 'is-invalid': submitted && $v.form.accountNumber.$error }"
        /></label>
        <small class="error" v-if="submitted && !$v.form.accountNumber.required"
          >Account number is required</small
        >
        <br />
        <br />
        <div class="d-flex">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 0.875C3.61758 0.875 0.875 3.61758 0.875 7C0.875 10.3824 3.61758 13.125 7 13.125C10.3824 13.125 13.125 10.3824 13.125 7C13.125 3.61758 10.3824 0.875 7 0.875ZM9.64551 4.9998L6.76621 8.99199C6.72597 9.04816 6.67292 9.09393 6.61145 9.1255C6.54999 9.15707 6.48189 9.17354 6.41279 9.17354C6.3437 9.17354 6.27559 9.15707 6.21413 9.1255C6.15267 9.09393 6.09962 9.04816 6.05938 8.99199L4.35449 6.62949C4.30254 6.55703 4.35449 6.45586 4.44336 6.45586H5.08457C5.22402 6.45586 5.35664 6.52285 5.43867 6.6377L6.41211 7.98848L8.56133 5.00801C8.64336 4.89453 8.77461 4.82617 8.91543 4.82617H9.55664C9.64551 4.82617 9.69746 4.92734 9.64551 4.9998Z"
              fill="#2A3447"
            />
          </svg>

          <p class="sm_text">Wallet only profile? Enter your 11 digits wallet number</p>
        </div>
        <div class="d-flex">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 0.875C3.61758 0.875 0.875 3.61758 0.875 7C0.875 10.3824 3.61758 13.125 7 13.125C10.3824 13.125 13.125 10.3824 13.125 7C13.125 3.61758 10.3824 0.875 7 0.875ZM9.64551 4.9998L6.76621 8.99199C6.72597 9.04816 6.67292 9.09393 6.61145 9.1255C6.54999 9.15707 6.48189 9.17354 6.41279 9.17354C6.3437 9.17354 6.27559 9.15707 6.21413 9.1255C6.15267 9.09393 6.09962 9.04816 6.05938 8.99199L4.35449 6.62949C4.30254 6.55703 4.35449 6.45586 4.44336 6.45586H5.08457C5.22402 6.45586 5.35664 6.52285 5.43867 6.6377L6.41211 7.98848L8.56133 5.00801C8.64336 4.89453 8.77461 4.82617 8.91543 4.82617H9.55664C9.64551 4.82617 9.69746 4.92734 9.64551 4.9998Z"
              fill="#2A3447"
            />
          </svg>

          <p class="sm_text">
            Else enter any of your 10 digits sterling account to begin
          </p>
        </div>
        <br />
      </div>
      <button type="submit" class="btn btn__red btn__group">
        <loading v-if="loading" />
        <span>Proceed</span>
      </button>
    </form>
  </div>
</template>

<script>
import api from "@/api/api.js";
import { required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      loading: false,
      submitted: false,
      form: {
        accountNumber: "",
        IsWallet: false,
      },
    };
  },
  validations: {
    form: {
      accountNumber: { required },
    },
  },
  methods: {
    submitForm() {
      if (this.form.accountNumber.length == 11) {
        this.IsWallet = true;
      }
      this.submitted = true;

      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        return;
      }

      let payload = {
        nuban: this.form.accountNumber,
        IsWallet: this.IsWallet,
      };

      this.loading = true;

      api
        .generateOTPWithAny(payload)
        .then((response) => {
          this.loading = false;
          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            let date = { ...response.Data, ...payload };
            this.$store.commit("setForgetPasswordData", date);

            let userDetails = response.Data.lastName + " " + response.Data.firstname;
            this.form.fullName = userDetails;
            this.$router.push("/password-reset/otp");
          } else {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
.sm_text {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  letter-spacing: 0.05em;
  padding-left: 1rem;
  color: #000000;
}
.d-flex {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
}
</style>
